var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isRequiredForA11y;

function isRequiredForA11y(validator) {
  return function validate(props, propName, componentName, location, propFullName) {
    var componentNameSafe = componentName || "<<anonymous>>";
    var propFullNameSafe = propFullName || propName;

    if (props[propName] == null) {
      return new Error("The " + location + " `" + propFullNameSafe + "` is required to make " + ("`" + componentNameSafe + "` accessible for users of assistive ") + "technologies such as screen readers.");
    }

    for (var _len = arguments.length, args = Array(_len > 5 ? _len - 5 : 0), _key = 5; _key < _len; _key++) {
      args[_key - 5] = arguments[_key];
    }

    return validator.apply(undefined, [props, propName, componentName, location, propFullName].concat(args));
  };
}

exports = exports["default"];
export default exports;
export const __esModule = exports.__esModule;